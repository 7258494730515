import React, { useEffect, useRef } from 'react'
import './About.css'
import { profile2 } from '../../images'
import gsap from 'gsap'
// eslint-disable-next-line no-unused-vars
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const About = () => {
    const container = useRef(null)
    gsap.registerPlugin(ScrollTrigger)
    useEffect(() => {
        const el = container.current;
        gsap.fromTo(".about__container", {
            scale: 0.7
        },
            {
                scale: 1,
                scrollTrigger: {
                    trigger: el,
                    scrub: true,
                }
            })
    }, [])
    return (
        <section id="about" ref={container}>
            <div className="section__wrapper about__container">
                <div className="me__container blur-effect">
                    <div className="photo__container">
                        <img src={profile2} alt="" />
                    </div>
                </div>
                <div className="section__header">
                    <h2 className="primary__title">About Us</h2>

                    <p className="text__muted description"><h1 className="title">Biashara <span className="color__primary"> Softwares</span></h1> specializes in delivering
                        top-notch website and web application development,
                        software solutions, and graphic design services.
                        With a focus on innovation and quality,
                        we empower businesses by creating tailored
                        digital experiences that drive success.
                        Let us help you bring your ideas to life and
                        stay competitive in the digital world.</p>
                </div>
            </div>
        </section>
    )
}

export default About
