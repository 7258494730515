import React from 'react'
import './Testimonials.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import { kush, peter, flex, hedge } from '../../images'


const data = [

    {
        avatar: kush,
        name: 'Joseph Kuria',
        review: "Biashara Softwares did an outstanding job building our portfolio website for NMBL Services. The site is modern, professional, and perfectly showcases our construction projects. They understood our vision and delivered exactly what we needed, with great attention to detail and excellent communication throughout the process. "
    },
    {
        avatar: flex,
        name: 'Mwangi Flexus',
        review: `Biashara Softwares delivered exceptional work for us at Flexus Design and Build, creating a company profile that truly captures our brand and expertise. They also designed stunning posters for Mwagi Construction Ltd., which have significantly boosted our marketing efforts. The team was professional, responsive, and fully committed to bringing our ideas to life.`
    },
    {
        avatar: peter,
        name: 'Peter Odhiambo',
        review: `Biashara Softwares was incredible when building a website for SYNERGY. They captured the essence of our mission to empower youth in Juja and created a site that is both visually appealing and easy to navigate. Their team was attentive to our needs and provided excellent support throughout the process. We are thrilled with the outcome.`
    },
    {
        avatar: hedge,
        name: 'Dinah Cheptoo',
        review: `Biashara Softwares did a great job creating our food delivery app. It is easy to use, works quickly, and has everything our customers need to place orders. The team was professional, listened to our feedback, and made changes whenever we asked.`
    }

];

const Testimonials = () => {
    return (
        <section id='testimonial'>
            <div className="section__wrapper">
                <div className="section__header">
                    <h2 className="primary__title">Testimonials</h2>
                    <p className="text__muted description">
                        See what our satisfied clients have to say about their experience
                        with Biashara 254 Softwares. From custom websites to powerful mobile apps,
                        our team is dedicated to delivering top-notch solutions that help businesses thrive.
                        Here are a few words from those who have trusted us with their projects
                    </p>
                </div>
                <Swiper className='testimonial__container'
                    modules={[Pagination]}
                    spaceBetween={40}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                        }
                    }}
                >
                    {
                        data.map(({ avatar, name, review }, index) => (
                            <SwiperSlide className='testimonial' key={index}>
                                <div className="client__avatar">
                                    <img src={avatar} alt={name} />
                                </div>
                                <h3 className="client__name">{name}</h3>
                                <small className="client__review">{review}</small>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>

            </div>
        </section>
    )
}

export default Testimonials
