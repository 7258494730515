import React, { useEffect, useRef } from 'react'
import './Services.css'
import { FaPaintBrush } from 'react-icons/fa'
import { FaComputer } from "react-icons/fa6";
import { FaMobileAlt } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import { Tb24Hours } from "react-icons/tb";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const Services = () => {
    const container = useRef(null)
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        const el = container.current
        gsap.fromTo('.service__head', {
            opacity: 0,
        },
            {
                opacity: 1,
                scrollTrigger: {
                    trigger: el,
                }
            }
        )
        gsap.fromTo('.service', {
            y: -50,
            opacity: 0,
        },
            {
                y: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: el,
                    start: "-100% bottom",
                    end: "bottom 20%",
                    scrub: true
                }
            }
        )
    }, [])
    return (
        <section id='services' ref={container}>
            <div className="section__wrapper services__wrapper">
                <div className="section_header center">
                    <h2 className="primary__title">Our Services</h2>
                    <p className="text__muted description">
                        At Biashara Softwares, our services are designed to
                        empower businesses with innovative and tailored
                        technology solutions. We specialize in offering
                        comprehensive software development, web design,
                        and IT consulting services that cater to the
                        unique needs of each client. Our team of skilled
                        professionals works closely with businesses to
                        understand their goals and challenges, delivering
                        cutting-edge solutions that enhance efficiency,
                        drive growth, and ensure a competitive edge in
                        the market. Whether you're a small startup or
                        a large enterprise, Biashara Softwares is committed
                        to providing high-quality services that help you
                        achieve your business objectives seamlessly.
                    </p>
                </div>
                <div className="services__group">
                    <article className="service">
                        <div className="service__top">
                            <div className="icon__container">
                                <FaPaintBrush className="icon" />
                            </div>
                            <h3 className="title">UI/UX Design</h3>
                        </div>
                        <div className="service__middle">
                            <p className="text__muted description">
                                Our team of expert designers creates visually
                                appealing and user-friendly interfaces that
                                enhance the overall user experience.
                                We focus on creating designs that are intuitive,
                                engaging, and easy to navigate, ensuring that
                                users can interact with your product seamlessly.
                            </p>
                        </div>
                        <div className="services__bottom">

                        </div>
                    </article>

                    {/* end of ui/ux */}

                    <article className="service" style={{ "--color-primary": "var(--color-red)" }}>
                        <div className="service__top">
                            <div className="icon__container">
                                <FaComputer className="icon" />
                            </div>
                            <h3 className="title">Custom Software</h3>
                        </div>
                        <div className="service__middle">
                            <p className="text__muted description">
                                Our developers create custom software solutions that are tailored
                                to meet the unique needs of your business.
                                Whether you need a custom CRM system,
                                an inventory management solution, or a web based POS
                                we have the expertise to deliver high-quality
                                software. We ensure that
                                your software is scalable, secure, and reliable,
                                giving you the peace of mind that your business is in good hands.
                            </p>
                        </div>
                        <div className="services__bottom">

                        </div>
                    </article>

                    {/* end of custom software */}

                    <article className="service">
                        <div className="service__top">
                            <div className="icon__container">
                                <FaMobileAlt className="icon" />
                            </div>
                            <h3 className="title">Mobile App</h3>
                        </div>
                        <div className="service__middle">
                            <p className="text__muted description">
                                We specialize in building custom mobile applications that are
                                tailored to meet the unique needs of your business.
                                Whether you need an iOS app, an Android app,
                                or a cross-platform app, we have the expertise
                                to deliver high-quality solutions that help you
                                reach your target audience and drive engagement.
                            </p>
                        </div>
                        <div className="services__bottom">

                        </div>
                    </article>

                    {/* end of mobile app */}

                    <article className="service" style={{ "--color-primary": "var(--color-success)" }}>
                        <div className="service__top">
                            <div className="icon__container">
                                <TbWorldWww className="icon" />
                            </div>
                            <h3 className="title">Web Development</h3>
                        </div>
                        <div className="service__middle">
                            <p className="text__muted description">
                                Our skilled team of web developers specialize in
                                designing and building web applications that are tailored
                                to meet the unique needs of your business.
                                We use the latest technologies and best practices
                                to deliver high-quality solutions that are scalable,
                                secure, and reliable.
                            </p>
                        </div>
                        <div className="services__bottom">

                        </div>
                    </article>

                    {/* end of web development */}

                    <article className="service">
                        <div className="service__top">
                            <div className="icon__container">
                                <FaPaintBrush className="icon" />
                            </div>
                            <h3 className="title">Graphic Design</h3>
                        </div>
                        <div className="service__middle">
                            <p className="text__muted description">
                                Our expert graphic designers come up with
                                visually stunning graphics that help
                                businesses stand out in the market.
                                From logos and branding to marketing materials
                                and social media graphics, we offer a wide range
                                of design services that help businesses make a
                                lasting impression on their audience.
                            </p>
                        </div>
                        <div className="services__bottom">

                        </div>
                    </article>

                    {/* end of graphic design */}

                    <article className="service" style={{ "--color-primary": "var(--color-muted)" }}>
                        <div className="service__top">
                            <div className="icon__container">
                                <Tb24Hours className="icon" />
                            </div>
                            <h3 className="title">24/7 Support</h3>
                        </div>
                        <div className="service__middle">
                            <p className="text__muted description">
                                We provide round-the-clock support and maintenance services
                                to ensure that your software applications are
                                running smoothly at all times. Whether you need
                                technical assistance, bug fixes, or updates,
                                we are here to help you resolve any issues
                                quickly and efficiently.
                            </p>
                        </div>
                        <div className="services__bottom">

                        </div>
                    </article>

                </div>
            </div>

        </section>
    )
}

export default Services
