import React from 'react'
import './Contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { BsWhatsapp } from 'react-icons/bs'
import { RiMessengerLine } from 'react-icons/ri'

const Contact = () => {
    return (
        <section id='contact'>
            <div className="section__wrapper contact__container">
                <div className="section__header">
                    <h2 className="primary__title">Contact Us</h2>
                    <p className="text__muted description">
                        Ready to get started on your project? Reach out to us today and let us know how we can help.
                    </p>
                </div>
                <div className="contact__group">
                    <div className="contact__options">
                        <article className="contact__option">
                            <MdOutlineEmail className="contact__icon" />
                            <h3>Email</h3>
                            <h5>254biashara@gmail.com</h5>
                            <a href="mailto:254biashara@gmail.com" target='_blank' rel='noreferrer' className='btn'>Send a message</a>
                        </article>

                        <article className="contact__option">
                            <BsWhatsapp className="contact__icon" />
                            <h3>Whatsapp</h3>
                            <h5>+254724341112</h5>
                            <a href="https://api.whatsapp.com/send?phone=254724341112" target='_blank' rel='noreferrer' className='btn'>Send a message</a>
                        </article>
                    </div>
                    <form action="">
                        <input type="text" name='name' placeholder='Your full name :' required />
                        <input type="email" name='email' placeholder='Your email :' required />
                        <textarea name="message" placeholder="Your message : " rows={7}> </textarea>
                        <button type='submit' className="btn btn__primary">Send message</button>
                    </form>
                </div>
            </div>

        </section>
    )
}

export default Contact
