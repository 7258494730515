import { DiReact } from "react-icons/di";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress, SiMongodb } from "react-icons/si";
import { BsInstagram } from "react-icons/bs";
import { AiFillLinkedin, AiFillYoutube } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa6";
import { FaCss3 } from "react-icons/fa6";
import { SiCanva } from "react-icons/si";
import { LuFigma } from "react-icons/lu";
import { SiJavascript } from "react-icons/si";
import { FaBootstrap } from "react-icons/fa6";

export const menu = [
  { name: "About" },
  { name: "Services" },
  { name: "Skills" },
  { name: "Projects" },
  { name: "Testimonial" },
  { name: "Contact" },
]



export const projects = [
  {
    id: 1,
    title: 'Construction Company Website',
    image: 'https://i.postimg.cc/3JYJQ3vr/nmbl-1.png',
    category: "Web",
    data: {
      description: `We recently built a modern, professional website for NMBL Services,
       a construction company that specializes in high-quality building and renovation projects.
        The site is designed to effectively showcase their portfolio, featuring detailed project
         galleries and client testimonials that highlight their expertise and commitment to excellence.
          The clean, user-friendly layout ensures that potential clients can easily navigate the site to 
          learn more about NMBL Services' offerings, while the responsive design guarantees a seamless 
          browsing experience across all devices. The website not only reflects the company's brand 
          and values but also serves as a powerful tool for attracting new business.`,
      demoLink: "https://nmblservices.co.ke/",
    },
    stack: [
      {
        name: "Javascript",
        icon: <SiJavascript />,
        iconColor: "skyblue",
      },
      {
        name: "HTML",
        icon: < FaHtml5 />,
        iconColor: "orange",
      },
      {
        name: "SCSS",
        icon: <FaCss3 />,
        iconColor: "blue",
      },
    ]
  },
  {
    id: 2,
    title: 'E-Commerce Application',
    image: 'https://i.postimg.cc/RVdCM277/ecommerce.png',
    category: "Web",
    data: {
      description: `We built an eCommerce website for Biashara 254, 
      a company focused on providing a wide range of products to customers across Kenya. 
      The website is designed to offer a seamless shopping experience, featuring an intuitive interface, 
      secure payment options, and a responsive layout for easy access on all devices. 
      The site also includes product categorization and search functionality to help customers 
      find what they need quickly and efficiently.`,
      demoLink: "https://fabulous-choux-74a824.netlify.app/",
    },
    stack: [
      {
        name: "ReactJs",
        icon: <DiReact />,
        iconColor: "skyblue",
      },
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "ExpressJs",
        icon: <SiExpress />,
      },
      {
        name: "MongoDB",
        icon: <SiMongodb />,
        iconColor: "limegreen",
      },
    ]
  },
  {
    id: 3,
    title: 'Charity Organization Website',
    image: 'https://i.postimg.cc/6QsmFp8Y/synergy-5.png',
    category: "Web",
    data: {
      description: `We designed and built a website for SYNERGY, a charity organization dedicated
       to empowering young school-going boys and girls in Juja, Kiambu County, Kenya. 
       The site features a user-friendly design that highlights the organization's mission, 
       projects, and impact. It includes responsive elements to ensure accessibility on various 
       devices and provides an easy way for visitors to learn more about SYNERGY's work, 
       get involved, and contribute to their cause.`,
      demoLink: "https://synergycbo.org/",
    },
    stack: [
      {
        name: "HTML",
        icon: < FaHtml5 />,
        iconColor: "orange",
      },
      {
        name: "Javascript",
        icon: <SiJavascript />,
        iconColor: "skyblue",
      },

      {
        name: "Bootsrap CSS",
        icon: <FaBootstrap />,
        iconColor: "blue",
      },
    ]
  },
  {
    id: 4,
    title: 'Hifadhi CPDR',
    image: 'https://i.postimg.cc/SNH2KLCQ/Blue-Professional-Hospital-Logo.png',
    category: "Apps",
    data: {
      description: `Hifadhi is a centralized patient data repository designed to 
      streamline healthcare management by securely storing and organizing patient records. 
      The system enables healthcare providers to access and update patient information efficiently, 
      ensuring that critical data is available when needed. Hifadhi enhances coordination across medical teams, 
      improves the accuracy of diagnoses and treatments, and ultimately contributes to better patient outcomes.`,
      demoLink: "https://google.com/",
    },
    stack: [
      {
        name: "ReactJs",
        icon: <DiReact />,
        iconColor: "skyblue",
      },
      {
        name: "NodeJs",
        icon: <FaNodeJs />,
        iconColor: "green",
      },
      {
        name: "MongoDB",
        icon: <SiMongodb />,
        iconColor: "limegreen",
      },
    ]
  },
  {
    id: 5,
    title: 'School Website wireframe design',
    image: 'https://i.postimg.cc/4xtWg840/synergy-2.png',
    category: "UI/UX",
    data: {
      description: `We created a clean, responsive interface that 
      ensures easy access to key information for students and parents. 
      The design features clear navigation, prominent announcements, and 
      a consistent visual identity aligned with the school's branding. 
      This project highlights our commitment to delivering user-friendly 
      and visually appealing digital solutions.`,
      demoLink: "https://google.com/",
    },
    stack: [
      {
        name: "Figma",
        icon: <LuFigma />,
        iconColor: "skyblue",
      },
      {
        name: "Canva",
        icon: <SiCanva />,
        iconColor: "green",
      },
    ]
  },
  {
    id: 6,
    title: 'Snake Game',
    image: 'https://i.postimg.cc/zf5DSXdG/synergy-3.png',
    category: "Fun",
    data: {
      description: `Biashara Softwares presents a fun and engaging 
      Snake game(Computer only) built using HTML, CSS, and JavaScript. This classic game 
      is designed with smooth controls and vibrant graphics, click on the DEMO button to play. 
      Players navigate the snake to collect food, growing longer with each bite while
       avoiding collisions. The simple yet addictive design demonstrates our ability to 
       create interactive and enjoyable web experiences, combining effective coding 
       with appealing visuals.`,
      demoLink: "https://snake-game-ten-pearl.vercel.app/index.html",
    },
    stack: [
      {
        name: "Javascript",
        icon: <SiJavascript />,
        iconColor: "skyblue",
      },
      {
        name: "HTML",
        icon: < FaHtml5 />,
        iconColor: "orange",
      },
      {
        name: "CSS",
        icon: <FaCss3 />,
        iconColor: "blue",
      },
    ]
  },
]



export const experience = [
  {
    title: "UI/UX",
    data: [
      {
        skill: "Figma",
        level: "Experienced",
      },
      {
        skill: "Canva",
        level: "Intermediate",
      },
      {
        skill: "XD",
        level: "Intermediate",
      },
    ]
  },
  {
    title: "Frontend Development",
    data: [
      {
        skill: "HTML",
        level: "Experienced",
      },
      {
        skill: "CSS",
        level: "Experienced",
      },
      {
        skill: "JavaScript",
        level: "Experienced",
      },
      {
        skill: "Tailwind",
        level: "Intermediate",
      },

      {
        skill: "React",
        level: "Experienced",
      },

    ]
  },
  {
    title: "Backend Development",
    data: [
      {
        skill: "Node JS",
        level: "Experienced",
      },
      {
        skill: "MongoDB",
        level: "Intermediate",
      },

      {
        skill: "Python : Django",
        level: "Intermediate",
      },
      {
        skill: "MySQL",
        level: "Experienced",
      },
    ]
  },
]


export const socialHandles = [
  {
    name: "Instagram",
    icon: <BsInstagram />,
    link: "https://www.instagram.com/biashara_254?igsh=MTc4bml1enZlc3Zucw==",
  },
  {
    name: "Facebook",
    icon: <FaFacebookSquare />,
    link: "https://www.facebook.com/biashara254?mibextid=ZbWKwL",
  },
  {
    name: "LinkedIn",
    icon: <AiFillLinkedin />,
    link: "https://www.linkedin.com/company/biashara254/",
  },
  {
    name: "Youtube",
    icon: <AiFillYoutube />,
    link: "https://youtube.com/@mrcreviews5169?si=hfvWa_z1gRbC6_-D",
  },
];